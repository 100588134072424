
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { useMemo } from 'react';
import useSWR from 'swr';
import type { LandingPageQuery } from 'generated/graphql';
import ContainerGrid from 'src/components/container-grid';
import ContentContainerBanner from 'src/components/content-container-banner';
import { FaqList } from 'src/components/faq-list';
import HeadTags from 'src/components/head-tags';
import LandingPageExclusiveContent from 'src/components/landing-page/landing-page-exclusive-content';
import LandingPageFooter from 'src/components/landing-page/landing-page-footer';
import LandingPageHero2022 from 'src/components/landing-page/landing-page-hero-2022';
import LandingPageHero2024 from 'src/components/landing-page/landing-page-hero-2024';
import LandingPageLive from 'src/components/landing-page/landing-page-live';
import MarketingDevices from 'src/components/marketing/marketing-devices';
import Page from 'src/components/page';
import RenderClientOnly from 'src/components/render-client-only';
import SubscriptionPlans from 'src/components/subscription-plans';
import useSdk from 'src/hooks/use-sdk';
import useTranslate from 'src/hooks/use-translate';
import { TrackingContext, TrackingContextValues } from 'src/tracking/tracking-context';
import { EmptyPageParameters, LandingPageLiveConcert, LandingPageNewAlbums, LandingPagePopularNow } from 'src/types';
import { isInAbTest } from 'src/utilities/ab-tests';
import { Faq, getFaqs } from 'src/utilities/get-faqs';
import { getCurrentLocale } from 'src/utilities/i18n-helpers';
import { incrementalServerSideWithQuery } from 'src/utilities/ssr-helpers';
// Width of an album cover in a slider or desktop
const sliderAlbumWidth = 160;
// Gap between album covers in a slider on desktop
const sliderAlbumGap = 30;
// Number of items we request for server side rendering
const sliderItemCountServerSide = 11;
// Returns number of items we need to fetch to cover the current screen
const getSliderItemCount = () => typeof window === 'undefined'
    ? undefined
    : Math.max(Math.ceil(window?.screen.width / (sliderAlbumWidth + sliderAlbumGap)), sliderItemCountServerSide);
type LandingPageProps = {
    fallbackData: LandingPageQuery;
    faqs: Faq[];
};
/**
 * Landing page showing the main features of the app. This page is only shown to non-authenticated users.
 */
function LandingPage({ fallbackData, faqs }: LandingPageProps) {
    const t = useTranslate();
    const sdk = useSdk();
    // Fetch the landing page data from the API, but only as much as we need for the current screen
    const { data } = useSWR('landing-page', () => sdk.landingPage({ sliderItemsFirst: getSliderItemCount() }), {
        fallbackData
    });
    // Select the banner
    const banner = useMemo(() => {
        const bannerNode = data.curatedContent.curatedContent.find((contentContainer) => contentContainer.__typename === 'Banner');
        // If the banner is not found, return null, otherwise confirm the type so TypeScript knows it's a Banner
        return bannerNode && bannerNode.__typename === 'Banner' ? bannerNode : null;
    }, [data?.curatedContent.curatedContent]);
    // Select the "Next Live Concerts" by item type "Live Concert"
    const liveConcerts = useMemo(() => {
        if (!data?.curatedContent.curatedContent)
            return [];
        const slider = data.curatedContent.curatedContent.find((container) => {
            return container.__typename === 'ListSlider' && container.items[0].__typename === 'LiveConcert';
        });
        return slider && slider.__typename === 'ListSlider' ? (slider.items as LandingPageLiveConcert[]) : null;
    }, [data?.curatedContent.curatedContent]);
    // Select the "Popular Now" slider by item type "VODConcert"
    const popularNowItems = useMemo(() => {
        const slider = data.curatedContent.curatedContent.find((container) => {
            return container.__typename === 'ListSlider' && container.items[0].__typename === 'VodConcert';
        });
        return slider && slider.__typename === 'ListSlider' ? (slider.items as LandingPagePopularNow[]) : null;
    }, [data?.curatedContent.curatedContent]);
    // Select the "New Albums" slider by item type "Album"
    const newAlbumsItems = useMemo(() => {
        const slider = data.curatedContent.curatedContent.find((container) => {
            return container.__typename === 'ListSlider' && container.items[0].__typename === 'Album';
        });
        return slider && slider.__typename === 'ListSlider' ? (slider.items as LandingPageNewAlbums[]) : null;
    }, [data?.curatedContent.curatedContent]);
    const subscriptionPlans = data?.subscriptionPlansStripe;
    // Show the new hero if the user is in the AB test variation 2
    const showNewHero = isInAbTest('xp2024-09-t1') === 'v2';
    return (<Page testId="landing-page">
      <HeadTags title={t('page__landing_title')}/>

      {/*
        Render the hero component only on the client, to avoid hydration issues with the a-b experiment
        It can be returned to default SSR render when the experiment is finished
        */}
      <RenderClientOnly>
        {showNewHero ? (<div className="mt-8">
            <LandingPageHero2024 />
          </div>) : (<LandingPageHero2022 />)}
      </RenderClientOnly>

      {/* Optional Banner */}
      {banner && <ContentContainerBanner banner={banner}/>}

      {/* Live Module */}
      {liveConcerts && <LandingPageLive liveConcerts={liveConcerts} className="pb-20 pt-12"/>}

      {/* Exclusive Content */}
      {popularNowItems && newAlbumsItems ? (<LandingPageExclusiveContent popularNowItems={popularNowItems} newAlbumItems={newAlbumsItems} 
        // Show the description only if the new hero is not shown
        showDescription={!showNewHero}/>) : null}

      {/* Ticket Module */}
      {subscriptionPlans && (<div className="pb-24 pt-12 2xl:pb-32">
          <ContainerGrid>
            <TrackingContext.Provider value={TrackingContextValues.LandingPageSubscriptionPlans}>
              <div className="col-span-full md:col-span-8 md:col-start-3 xl:col-span-6 xl:col-start-4">
                <SubscriptionPlans subscriptionPlans={subscriptionPlans.plans} titleTranslationKey="page__landing_subscribe"/>
              </div>
            </TrackingContext.Provider>
          </ContainerGrid>
        </div>)}

      {/* Devices Module */}
      <MarketingDevices />

      {/* FAQs */}
      {faqs.length > 0 && (<>
          <h2 className="dg-text-medium-1 mb-14 text-center md:mb-12">{t('marketing_devices__faqs')}</h2>
          <ContainerGrid>
            <div className="col-span-full pb-16 md:pb-20 2xl:col-span-8 2xl:col-start-3">
              <FaqList faqs={faqs}/>
            </div>
          </ContainerGrid>
        </>)}
    </Page>);
}
// the landing page footer is a separate component
LandingPage.footerComponent = LandingPageFooter;
export default LandingPage;
const getStaticProps = incrementalServerSideWithQuery<EmptyPageParameters, LandingPageProps>(async ({ context, sdk }) => {
    const fallbackData = await sdk.landingPage();
    const currentLocale = getCurrentLocale(context.locale);
    const faqs = await getFaqs({ locale: currentLocale });
    return {
        props: { fallbackData, faqs },
        revalidate: 3600
    };
});

    async function __Next_Translate__getStaticProps__191c1fd212a__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__191c1fd212a__ as getStaticProps }
  