import { type ReactNode } from 'react';
import CheckmarkIcon from '@stageplus/icons/react/checkmark';
import SmallChevronIcon from '@stageplus/icons/react/small-chevron';
import Link from 'next/link';
import SignupCtaButton from 'src/components/signup-cta-button';
import useTranslate from 'src/hooks/use-translate';
import { TrackingContext, TrackingContextValues } from 'src/tracking/tracking-context';

function FeatureList({ children }: { children: ReactNode }) {
  return <ul className="space-y-2">{children}</ul>;
}

function FeatureListItem({ children }: { children: ReactNode }) {
  return (
    <li className="flex items-center space-x-3 text-textSecondary typo-body-1">
      <span className="block size-[18px] shrink-0" aria-hidden>
        <CheckmarkIcon className="size-full rounded-full bg-buttonBackground text-buttonForeground" />
      </span>
      <span>{children}</span>
    </li>
  );
}

/** Hero signup form */
export default function LandingPageHeroSignupForm() {
  const t = useTranslate();

  return (
    <div className="relative flex flex-col space-y-6 bg-surface-600 p-6 before:absolute before:inset-x-0 before:top-0 before:h-px before:bg-[radial-gradient(circle_at_50%_-50%,hsl(var(--twc-brand-dg)),hsl(var(--twc-black)/0)_90%)] md:rounded-3xl md:p-8">
      <div className="space-y-4">
        <header className="space-y-2">
          <h2 className="text-textPrimary typo-title-2">{t('landing_hero__signup_form_heading')}</h2>
        </header>
        <FeatureList>
          <FeatureListItem>{t('landing_hero__signup_feature_1')}</FeatureListItem>
          <FeatureListItem>{t('landing_hero__signup_feature_2')}</FeatureListItem>
          <FeatureListItem>{t('landing_hero__signup_feature_3')}</FeatureListItem>
          <FeatureListItem>{t('landing_hero__signup_feature_4')}</FeatureListItem>
        </FeatureList>
      </div>
      <div>
        <TrackingContext.Provider value={TrackingContextValues.LandingPageHero}>
          <SignupCtaButton fullWidth />
        </TrackingContext.Provider>
      </div>
      <div>
        <Link
          href="/discover"
          className="inline-flex items-center text-support-info typo-subheadline hover:text-textWhite focus-visible:focus-outline"
        >
          <span>{t('landing_hero__footer_link_label')}</span>
          <span className="-mr-2">
            <SmallChevronIcon className="inline-block" aria-hidden />
          </span>
        </Link>
      </div>
    </div>
  );
}
