import className from 'clsx';
import Image, { type StaticImageData } from 'next/image';
import sourceImageKarajan from 'public/images/landing-page/carousel/tail-album-karajan.jpeg';
import sourceImageVíkingur from 'public/images/landing-page/carousel/tail-album-vikingur.jpeg';
import sourceImageWang from 'public/images/landing-page/carousel/tail-album-wang.jpeg';
import sourceImageMallwitz from 'public/images/landing-page/carousel/tail-poster-mallwitz.jpeg';
import sourceImageSierra from 'public/images/landing-page/carousel/tail-poster-sierra.jpeg';
import sourceImageYannick from 'public/images/landing-page/carousel/tail-poster-yannick.jpeg';
import sourceImageYunchan from 'public/images/landing-page/carousel/tail-poster-yunchan.jpeg';

import { Carousel } from 'src/components/carousel';
import { cloudinaryLoader } from 'src/utilities/image-sdk';

// Hardcoded carousel items with album covers and performer portraits
const carouselItems: { image: StaticImageData; type: 'poster' | 'album'; caption: string }[] = [
  {
    image: sourceImageMallwitz,
    type: 'poster',
    caption: 'Joana Mallwitz',
  },
  {
    image: sourceImageYunchan,
    type: 'poster',
    caption: 'Yunchan Lim',
  },
  {
    image: sourceImageWang,
    type: 'album',
    caption: 'The Vienna Recital by Yuja Wang',
  },
  {
    image: sourceImageYannick,
    type: 'poster',
    caption: 'Yannick Nézet-Séguin',
  },
  {
    image: sourceImageVíkingur,
    type: 'album',
    caption: 'Bach’s Goldberg Variations by Víkingur Ólafsson',
  },
  {
    image: sourceImageSierra,
    type: 'poster',
    caption: 'Nadine Sierra',
  },
  {
    image: sourceImageKarajan,
    type: 'album',
    caption: 'Richard Strauss by Herbert von Karajan',
  },
];

/** TV carousel with album covers and performer portraits */
export default function LandingPageCarousel() {
  return (
    <Carousel itemSlideDuration={4000}>
      {carouselItems.map(({ image, type, caption }) => (
        <Image
          key={caption}
          src={image}
          alt={caption}
          className={className(
            'mr-6 rounded-2xl',
            type === 'album' ? 'size-[152px] md:size-[280px]' : 'h-[152px] w-[104px] md:h-[280px] md:w-[190px]',
          )}
          width={type === 'album' ? 152 : 280}
          height={280}
          loader={cloudinaryLoader}
        />
      ))}
    </Carousel>
  );
}
