import LandingPageCarousel from 'src/components/landing-page/landing-page-hero-carousel';
import LandingPageHeroSignupForm from 'src/components/landing-page/landing-page-hero-signup-form';
import { LandingPageTvVideo } from 'src/components/landing-page/landing-page-video';
import useTranslate from 'src/hooks/use-translate';

/**
 * Hero section for the landing page with a mock TV and a content carousel
 * Originally built for the 2024 summer landing page
 **/
export default function LandingPageHero2024() {
  const t = useTranslate();

  return (
    <div className="md:px-8 md:pb-8">
      <div className="mx-auto flex h-[25rem] max-w-[90rem] flex-col bg-[url('/images/landing-page/room-with-tv.avif')] bg-cover bg-center bg-no-repeat md:h-[46.75rem] md:rounded-4xl">
        <div className="flex h-[7.9rem] items-center justify-center px-4 md:h-[14.7rem]">
          <h1 className="max-w-[22rem] text-center text-textPrimary typo-title-1 md:max-w-[40rem] md:typo-large-title">
            {t('landing_hero__headline')}
          </h1>
        </div>
        <div className="mt-[2.2rem] flex h-[9.5rem] gap-6 overflow-hidden pl-[calc((100%-20rem)/2)] md:mt-[4.2rem] md:h-[17.5rem] md:pl-[calc((100%-38rem)/2)]">
          <div className="hidden aspect-video h-full motion-safe:md:block">
            <div className="size-full overflow-hidden rounded-2xl">
              <LandingPageTvVideo />
            </div>
          </div>
          <div className="w-full overflow-hidden rounded-l-2xl">
            <LandingPageCarousel />
          </div>
        </div>
      </div>
      <div data-test="signup-form-hero" className="mx-auto dark md:-mt-24 md:max-w-[34rem]">
        <LandingPageHeroSignupForm />
      </div>
    </div>
  );
}
